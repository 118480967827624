const FEATURE_FLAGS = {
  metric_enabled: "on",
  live_event: "off",
  "kiosk-unity": "on",
  lab: "on",
  "unity-simulator": "on",
  play_tier_status: "on",
  stroke_play: "on",
  video_review: "on",
  video_capture: "on",
};

export type FeatureFlag = keyof typeof FEATURE_FLAGS;

export type FeatureFlags = {
  [key in FeatureFlag]: string;
};

export default FEATURE_FLAGS;
