// src/hooks/useSelection.ts
import { useCallback, useState } from "react";

function useSelection<T>(initialItems: T[] = []) {
  const [selectedItems, setSelectedItems] = useState<Set<T>>(new Set(initialItems));

  const selectItem = useCallback((item: T) => {
    setSelectedItems((prevSelected) => new Set(prevSelected).add(item));
  }, []);

  const deselectItem = useCallback((item: T) => {
    setSelectedItems((prevSelected) => {
      const newSelected = new Set(prevSelected);
      newSelected.delete(item);
      return newSelected;
    });
  }, []);

  const toggleItem = useCallback((item: T) => {
    setSelectedItems((prevSelected) => {
      const newSelected = new Set(prevSelected);
      if (newSelected.has(item)) {
        newSelected.delete(item);
      } else {
        newSelected.add(item);
      }
      return newSelected;
    });
  }, []);

  const clearSelection = useCallback(() => {
    setSelectedItems(new Set());
  }, []);

  const isSelected = useCallback((item: T) => selectedItems.has(item), [selectedItems]);

  return {
    selectedItems: Array.from(selectedItems),
    selectItem,
    deselectItem,
    toggleItem,
    clearSelection,
    isSelected,
  };
}

export default useSelection;
