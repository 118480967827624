import { getUserThrowSummaries } from "@/firebase/collections";
import { firebaseApp } from "@/firebaseConfig";
import { groupByTimestamp } from "@/hooks/useGroupBy";
import useSelection from "@/hooks/useSelection";
import { useUser } from "@/hooks/useUser";
import type { ThrowSummary, VideoMetadata } from "@/model/throwSummary";
import { formatThrowSummary } from "@/utils/throw";
import {
  CheckCircleRounded,
  CheckCircleTwoTone,
  CircleTwoTone,
  CompareArrowsRounded,
  FullscreenTwoTone,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Container,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  ListSubheader,
  Stack,
} from "@mui/material";
import { Video } from "@tdisc/video";
import { format } from "date-fns";
import {
  limit,
  onSnapshot,
  orderBy,
  query,
  where,
  type QuerySnapshot,
  type Timestamp,
} from "firebase/firestore";
import { getDownloadURL, getStorage, ref } from "firebase/storage";
import { LayoutGroup, motion } from "framer-motion";
import { Fragment, useCallback, useEffect, useRef, useState } from "react";
type VideoResource = {
  videoUrl?: string;
  thumbnailUrl?: string;
  metadata: VideoMetadata;
  throwTime: Timestamp;
  summary: ThrowSummary;
};

export default function VideoReviewRoute() {
  const [{ user }] = useUser();
  const [videos, setVideos] = useState<VideoResource[]>([]);
  const [isSelecting, setIsSelecting] = useState(false);
  const { selectedItems, toggleItem, clearSelection, isSelected } = useSelection<VideoResource>();
  const [playlistVideos, setPlaylistVideos] = useState<Record<string, VideoResource[]>>({});
  const [focusVideo, setFocusVideo] = useState<VideoResource | null>(null);
  const comparisonMode = "single_video";
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!user?.uid) {
      return;
    }
    onSnapshot(
      query(
        getUserThrowSummaries(user.uid),
        where("videoMetadata.coordinator.type", "==", "video"),
        orderBy("throwTime", "desc"),
        limit(50),
      ),
      async (snapshot: QuerySnapshot<ThrowSummary>) => {
        const throwSummaries = snapshot.docs.map((doc) => doc.data());
        const videos = await Promise.all(
          throwSummaries.map<Promise<VideoResource>>(async (throwSummary) => ({
            videoUrl: await getResourceUrl(throwSummary.videoMetadata?.coordinator?.fullFilePath),
            thumbnailUrl: await getResourceUrl(
              throwSummary.videoMetadata?.coordinator?.thumbnailPath,
            ),
            metadata: throwSummary.videoMetadata!,
            throwTime: throwSummary.throwTime,
            summary: await formatThrowSummary(throwSummary)!,
          })),
        );
        setVideos(videos.filter((video) => video.videoUrl));
      },
    );
  }, [user]);

  const getResourceUrl = async (filePath: string | undefined) => {
    if (!filePath) {
      return "";
    }
    try {
      const storage = getStorage(firebaseApp);
      const url = await getDownloadURL(ref(storage, filePath));
      return url;
    } catch (error) {
      console.error("Error getting resource URL", error);
      return;
    }
  };

  const handleComparison = useCallback(
    (videos: VideoResource[]) => {
      const groupedVideos = groupByTimestamp(videos, "throwTime");
      if (comparisonMode === "single_video") {
        const [initialVideo] = videos;
        setFocusVideo(initialVideo);
      }
      setPlaylistVideos(groupedVideos);
    },
    [comparisonMode],
  );

  const createComparison = () => {
    if (selectedItems.length < 2) {
      return;
    }

    handleComparison(selectedItems);
    clearSelection();
  };

  return (
    <Container maxWidth="desktop" sx={{ mx: "0 auto", width: "100%" }}>
      <Stack
        direction="column"
        spacing={2}
        sx={{ mx: "0 auto", width: "100%", "&:fullscreen": { width: "100vw", height: "100vh" } }}
        ref={containerRef}
      >
        {Object.keys(playlistVideos).length <= 0 ? (
          <>
            <Stack direction="row" spacing={2}>
              <Button
                onClick={() => (isSelecting ? createComparison() : setIsSelecting(!isSelecting))}
              >
                {isSelecting ? (
                  <>
                    <CheckCircleRounded /> Done
                  </>
                ) : (
                  <>
                    <CompareArrowsRounded /> Compare
                  </>
                )}
              </Button>
            </Stack>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "repeat(auto-fill, 320px)",
                position: "relative",
                gap: 2,
              }}
            >
              {videos.map((video) => (
                <Stack key={video.thumbnailUrl} direction="column" alignItems={"center"}>
                  <Box component={Button} position={"relative"} onClick={() => toggleItem(video)}>
                    {isSelecting && (
                      <Box
                        sx={{
                          position: "absolute",
                          width: "100%",
                          height: "100%",
                          display: "flex",
                          alignItems: "flex-start",
                          justifyContent: "flex-end",
                          borderRadius: 2,
                          p: 2,
                        }}
                      >
                        {isSelected(video) ? (
                          <CheckCircleTwoTone
                            sx={{
                              borderRadius: "50%",
                              backgroundColor: "primary.main",
                              fill: "white",
                              height: "28px",
                              width: "28px",
                            }}
                          />
                        ) : (
                          <CircleTwoTone
                            sx={{
                              color: "white",
                              height: "28px",
                              width: "28px",
                            }}
                          />
                        )}
                      </Box>
                    )}
                    <Box
                      layoutId={video.videoUrl}
                      component={motion.img}
                      whileHover={isSelecting ? { scale: 1.03 } : {}}
                      width={320}
                      height={180}
                      sx={{
                        borderRadius: 2,
                        objectPosition: "center",
                        m: 0,
                      }}
                      src={video.thumbnailUrl}
                    />
                  </Box>
                </Stack>
              ))}
            </Box>
          </>
        ) : (
          <LayoutGroup>
            {comparisonMode === "single_video" && (
              <Stack>
                <Stack direction="row" gap={2} justifyContent={"flex-end"} alignItems="center">
                  <IconButton onClick={() => containerRef.current?.requestFullscreen()}>
                    <FullscreenTwoTone
                      color="primary"
                      sx={{ backgroundColor: "white", borderRadius: "50%" }}
                    />
                  </IconButton>
                </Stack>
                <Stack direction="row" spacing={1} sx={{ width: "100%" }} maxHeight="491px">
                  <Stack
                    sx={{
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Video
                      key={focusVideo?.videoUrl}
                      src={focusVideo?.videoUrl}
                      poster={focusVideo?.thumbnailUrl}
                      frameRate={focusVideo?.metadata.coordinator.frameRate}
                    />
                  </Stack>
                  <ImageList
                    cols={1}
                    component={motion.div}
                    sx={{
                      gap: 2,
                      overflowY: "auto",
                      flexGrow: 0,
                      scrollBehavior: "smooth",
                      scrollSnapType: "y proximity",
                      borderRadius: 2,
                      borderTopLeftRadius: 0,
                      borderTopRightRadius: 0,
                      "&::-webkit-scrollbar": {
                        display: "none",
                      },
                      minWidth: "270px",
                    }}
                    ref={(ref) => {
                      if (ref) {
                        ref.addEventListener("scroll", (e) => {
                          e.stopPropagation();
                        });
                      }
                    }}
                  >
                    {Object.entries(playlistVideos).map(([date, videos]) => (
                      <Fragment key={date}>
                        <ImageListItem
                          sx={{
                            scrollSnapAlign: "none",
                          }}
                        >
                          <ListSubheader
                            sx={{
                              lineHeight: "1.25rem",
                              backgroundColor: "primary.dark",
                              color: "primary.light",
                              p: 1.5,
                              borderRadius: 2,
                            }}
                          >
                            {date}
                          </ListSubheader>
                        </ImageListItem>
                        {videos.map((video) => (
                          <ImageListItem key={video.thumbnailUrl}>
                            <Box
                              component="img"
                              src={video.thumbnailUrl}
                              sx={{
                                aspectRatio: 16 / 9,
                                scrollSnapAlign: "center",
                                cursor: "pointer",
                                borderRadius: 2,
                              }}
                              onClick={() => setFocusVideo(video)}
                              width={270}
                              loading="lazy"
                              height={150}
                              alt={video.throwTime.toDate().toLocaleString()}
                            />
                            <ImageListItemBar
                              sx={{
                                borderRadius: 2,
                                borderTopLeftRadius: 0,
                                borderTopRightRadius: 0,
                                "& .MuiImageListItemBar-titleWrap": {
                                  py: 0.5,
                                  px: 1,
                                },
                                "& .MuiImageListItemBar-title": {
                                  fontSize: "0.8rem",
                                },
                              }}
                              title={format(video.throwTime.toDate(), "h:mm a")}
                            />
                          </ImageListItem>
                        ))}
                      </Fragment>
                    ))}
                  </ImageList>
                </Stack>
              </Stack>
            )}
          </LayoutGroup>
        )}
      </Stack>
    </Container>
  );
}
