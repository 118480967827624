// src/hooks/useGroupBy.ts

import { format } from "date-fns";

type Comparator<T> = (a: T, b: T) => number;

export function groupBy<T>(
  data: T[],
  keyFn: (item: T) => string,
  comparator?: Comparator<T>,
): Record<string, T[]> {
  const groupedData: Record<string, T[]> = data.reduce(
    (acc, item) => {
      const groupKey = keyFn(item);
      if (!acc[groupKey]) {
        acc[groupKey] = [];
      }
      acc[groupKey].push(item);
      return acc;
    },
    {} as Record<string, T[]>,
  );

  if (comparator) {
    for (const group in groupedData) {
      groupedData[group].sort(comparator);
    }
  }

  return groupedData;
}

export function groupByRange<T>(
  data: T[],
  numberKey: keyof T,
  rangeSize: number,
): Record<string, T[]> {
  return groupBy(data, (item) => {
    const number = item[numberKey] as unknown as number;
    const rangeStart = Math.floor(number / rangeSize) * rangeSize;
    const rangeEnd = rangeStart + rangeSize;
    return `${rangeStart}-${rangeEnd}`;
  });
}

export function groupByTimestamp<T>(data: T[], timestampKey: keyof T): Record<string, T[]> {
  return groupBy(data, (item) => {
    const timestamp = item[timestampKey] as unknown as { seconds: number; nanoseconds: number };
    const date = new Date(timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000);
    return format(date, "MM/dd/yyyy"); // Format as MM/DD/YYYY using date-fns
  });
}
