import { Box, Container, Stack } from "@mui/material";
import { AnimatePresence } from "framer-motion";
import { useMemo } from "react";
import { useCollectionData, useDocumentData } from "react-firebase-hooks/firestore";
import { useTranslation } from "react-i18next";
import { Link, Outlet, useParams } from "react-router-dom";
import { getUserDiscPresets } from "../../firebase/collections";
import { StockShot } from "../../firebase/converters/stockShot";
import { getUserDiscPreset } from "../../firebase/docs";
import { useStockShots } from "../../hooks/useStockShots/useStockShots";
import { useUser } from "../../hooks/useUser";
import { DiscClass, DiscPreset } from "../../model/discs";
import AddDiscButton from "../discs/AddDiscButton";
import { discClassRouteConfig } from "../discs/config";
import GroupSidebar from "../discs/GroupSidebar";
import GroupSidebarItem from "../discs/GroupSidebarItem";
import { ErrorBoundary } from "../ErrorBoundary";
import RouteLayout from "../layout/RouteLayout";
import { containerVariants, itemVariants } from "../routes/variants";
import Section from "../Section";
import { Heavy } from "../Text";

export type DiscPresetByClass = {
  [key in DiscClass]: DiscPreset[];
};

export interface DiscsRouteOutletContext {
  discPresets: DiscPreset[];
  discPresetsByClass: DiscPresetByClass;
  discPresetsError: Error;
  legacyPresets: DiscPreset[];
  discPreset: DiscPreset;
  discPresetLoading: boolean;
  discPresetError: Error;
  stockShots: StockShot[];
  stockShotsLoading: boolean;
  stockShotsError: Error;
}

export default function DiscsLayout() {
  const [{ userId }] = useUser();
  const { discId, discClass } = useParams();
  const [discPresets, presetsLoading, discPresetsError] = useCollectionData(
    getUserDiscPresets(userId),
  );
  const [discPreset, discPresetLoading, discPresetError] = useDocumentData(
    getUserDiscPreset(userId, discId),
  );

  const [stockShots, stockShotsLoading, stockShotsError] = useStockShots({
    userId,
  });

  const { t } = useTranslation();

  const [discPresetsByClass, legacyPresets] = useMemo(
    () =>
      discPresets
        ? discPresets.reduce(
            ([presetByClass, legacyPresets], curr) => {
              if (curr && curr.class) {
                presetByClass[curr.class].push(curr);
              } else {
                legacyPresets.push(curr);
              }
              return [presetByClass, legacyPresets];
            },
            [
              {
                putter: [],
                "putt-approach": [],
                midrange: [],
                fairway: [],
                control: [],
                distance: [],
              } as DiscPresetByClass,
              [] as DiscPreset[],
            ],
          )
        : [],
    [discPresets],
  );

  return presetsLoading ? null : (
    <RouteLayout containerVariants={containerVariants}>
      <Container
        component={Stack}
        sx={{
          display: "flex",
          gap: 1,
          my: { mobile: 2, md: 4 },
        }}
      >
        <Section itemVariants={itemVariants}>
          <Stack gap={1} width="100%">
            <Stack justifyContent="space-between" alignItems="center" direction="row">
              <Box
                component={Link}
                sx={{
                  width: "fit-content",
                  textDecoration: "none",
                  userSelect: "none",
                  color: "secondary.main",
                  "&:focus": { textDecoration: "none" },
                  "&:hover": { textDecoration: "none", color: "primary.main" },
                  "&:visited": { textDecoration: "none" },
                  "&:active": { textDecoration: "none" },
                }}
                to="."
                relative="route"
              >
                <Heavy sx={{ fontSize: { mobile: 24, md: 32 } }} color="grey.700" spacing="dense">
                  {t("discCollection")}
                </Heavy>
              </Box>
              <AddDiscButton />
            </Stack>
            <GroupSidebar>
              <GroupSidebarItem
                to="/discs"
                label={t("allItem", { item: t("disc_capital_other") })}
                loading={presetsLoading}
                isSelected={!discClass && !discId}
              />
              {discClassRouteConfig.map((collection) => (
                <GroupSidebarItem
                  to={collection.collectionPath}
                  key={collection.id}
                  label={collection.navLabel}
                  loading={presetsLoading}
                  isSelected={collection.id === discClass}
                />
              ))}
            </GroupSidebar>
          </Stack>
        </Section>
        <ErrorBoundary eventName="disc_presets_error">
          <AnimatePresence>
            <Outlet
              context={{
                discPreset,
                discPresetError,
                discPresetLoading,
                discPresets,
                discPresetsByClass,
                discPresetsError,
                legacyPresets,
                stockShots,
                stockShotsLoading,
                stockShotsError,
              }}
            />
          </AnimatePresence>
        </ErrorBoundary>
      </Container>
    </RouteLayout>
  );
}
